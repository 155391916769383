<template>
  <v-app>
    <transition name="fade-transition" v-if="isVerified">
      <router-view />
    </transition>
    <download-update
      v-else
      @success="isVerified = true"
      transition="fade-transition"
    />
    <qrcode-stream v-if="showScanner" @decode="onDecode"></qrcode-stream>
    <v-btn
      fixed
      @click="closeScanner"
      v-if="showScanner"
      style="left:calc(50vw - 30px);bottom:20px;"
      fab
      dark
    >
      <v-icon>mdi-close</v-icon>
    </v-btn>
  </v-app>
</template>

<script>
import DownloadUpdate from "@/components/DownloadUpdate.vue";
import { QrcodeStream } from "vue-qrcode-reader";

export default {
  name: "App",
  components: { DownloadUpdate, QrcodeStream },
  computed: {
    showScanner() {
      return this.$store.getters["Asset/getShowScanner"] || false;
    },
  },
  data() {
    return {
      isVerified: false,
    };
  },
  methods: {
    onDecode(decodedString) {
      this.closeScanner();
      var assetID = decodedString.split("?assetid=")[1];
      if (assetID) {
        this.$store.commit("Asset/SET_ASSET_ID", assetID);
      }
      var episodeID = decodedString.split("?episodeid=")[1];
      if (episodeID) {
        this.$store.commit("Public/SET_EPISODE_NAME", episodeID);
      }
    },
    closeScanner() {
      this.$store.commit("Asset/CLOSE_SCANNER");
    },
  },
};
</script>

<style lang="scss">
@import "./App.scss";
</style>
