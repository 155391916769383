const state = {
  assetID: null,
  showScanner: false,
};

const getters = {
  getAssetID(state) {
    return state.assetID;
  },
  getShowScanner(state) {
    return state.showScanner;
  },
};

const mutations = {
  SET_ASSET_ID(state, payload) {
    state.assetID = payload;
  },
  OPEN_SCANNER() {
    state.showScanner = true;
  },
  CLOSE_SCANNER() {
    state.showScanner = false;
  },
};

export default {
  namespaced: "Asset",
  state,
  mutations,
  getters,
};
