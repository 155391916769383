var languageConfigs = {
  asset: {
    assetName: "Medicine / asset name",
    assetCode: "Product code",
    description: "Description",
    type: "Asset type",
    amount: "Amount in stock",
    price: "Price per item",
    asset: "Stationary asset",
    consumable: "Consumable asset",
    advancesetting: "Advance settings",
    instock: "Stock",
    create: "Create new medicine / asset",
    createAssetConfirmMessage:
      "After creation you cannot change asset type. Do you want to continue?",
    delete: "Delete medicine / asset",
    deleteAssetConfirmMessage:
      "Delete this asset cannot be undone. Do you want to continue?",
    showQr: "Show QR code",
    hideQr: "Hide QR code",
    importInventory: "Update in-stock amount",
    amountChange: "Amount increase/decrease",
    reasonChange: "Update note",
    consumeRequest: "Usage request",
    consumeAmmount: "Item amount",
    consumeDescription: "Usage description",
    consumePrice: "Usage price",
    consumePriceEach: "Price per item",
    consumeAmmountShort: "Amount",
    addItem: "Choose",
    confirm: "Confirm",
    confirmMessage: "Are are sure to complete this action?",
  },
  user: {
    profile: "Peronal Profile",
    setting: "Setting",
    logout: "Logout",
    phone: "Phone",
    fullName: "Full Name",
    dob: "Birthdate",
    gender: "Gneder",
    email: "Email",
    address: "Address",
    occupation: "Occupation",
    country: "Country",
    call: "Call",
    password: "Password",
    profileUrl: "Profile URL",
  },
  booking: {
    time: "Booking Time",
    price: "Price",
    complaint: "Chief Complaint",
    note: "Clinical Note",
    myappointment: "My Appointment",
  },
  episode: {
    myepisode: "My Episode",
    exam: "Episode Content",
    info: "Episode Information",
    checkintime: "Check-in Time",
    clinicaldatanotavailable: "Mental info not available",
    success: "Episode Success",
    canceled: "Episode Canceled",
  },
  clinic: {
    description: "Description",
    tax: "Tax Number",
    name: "Clinic Name",
    hotline: "Hotline",
  },
  service: {
    name: "Service Name",
    price: "Price",
    description: "Session info",
    noproviders: "No therapists yet.",
  },
  home: {
    welcome: "Welcome",
    haveaniceday: "Have a nice day at work!",
    report: "Report",
    event: "Event",
    episode: "Episode",
    notification: "Notification",
  },
  auth: {
    or: "Or",
    emailorphone: "Email / Phone",
    login: "Login",
    signup: "Signup",
    verifyphone: "Verify your phone number",
    sendcode: "Send code",
    alreadyhaveaccount: "Already have an account?",
    donthaveaccount: "Dont have an account?",
  },
  register: {
    editlogo: "Edit logo",
    verifyCode: "Verify Code",
    becomedoctor: "Register therapist account",
    registerclinic: "Register Organiztion",
    registeraccount: "Register Account",
    register: "Register",
    continue: "Continue",
    additionalinfo: "Additional Info",
    male: "Male",
    female: "Female",
    completed: "Completed",
    startusingapp: "Start using app",
    message1:
      "These information is used to provide best experience and services for our users.",
    message2: "Thank you for signing up at Sandrasoft!",
    message3:
      "We will contact you within 24h to verify. In the meatime you can start using our apllication.",
  },
  common: {
    back: "Back",
    search: "Search",
    checkin: "Check-in",
    appoint: "Appointment",
    choose: "Choose",
    selecttoview: "Select an item to view",
    selectdatetime: "Select date & time",
    create: "Create",
    info: "Information",
    detail: "Detailed information",
    select: "Select",
    datetime: "Date & time",
    confirm: "Confirm",
    confirmed: "Confirmed",
    delete: "Delete",
    cancel: "Cancel",
    save: "Save",
    finish: "Finish",
    selectfile: "Select file",
    today: "Today",
    at: "At",
    invite: "Invite",
    user: "User",
    notprovide: "Not provided",
    nodata: "No data found",
    awesomenodata: "Awesome! No notification yet.",
    lastvisits: "Latest episodes",
    verifyUpdate: "Starting application. Please wait a moment...",
    serverunavailable: "Server is under maintainance. Please come back later.",
    backtohome: "Back to home",
    timeStart: "Time start",
    timeEnd: "Time end",
    examcontent: "Session content",
    clinicaldata: "Mental note for therapist",
    account: "Account",
    report: "Report",
    month: "Month",
    rebooking: "Re-appointment",
    open: "Open website",
    title: "Title",
    headline: "Headline",
    share: "Share",
    unshare: "Unshare",
    confirmShare:
      "Share this information to be viewed by other people. Do you wish to continue?",
    confirmUnshare:
      "Unshare this information, people cannot see this information. Do you wish to continue?",
    change: "Change",
    oldPassword: "Old password",
    newPassword: "New password",
    retypePassword: "Re-type new password",
    changePasswordFailMessage: "Change password failed!",
    changePasswordSuccessMessage:
      "Change password success! Please login again.",
  },
  nav: {
    home: "Home",
    patient: "Client",
    doctor: "Therapist",
    booking: "Booking",
    episode: "Episode",
    search: "Search",
    setting: "Setting",
    clinic: "Clinic",
    service: "Service",
  },
  cardTitle: {
    clinic: "My Clinic",
    clinics: "Clinics",
    patients: "Clients",
    bookings: "Bookings",
    episodes: "Episodes",
    doctors: "Therapists",
    services: "Services",
    assets: "Medicine - assets",
  },
  error: {
    phonealreadyinuse: "Phone number already in use, please use it to login!",
    verifycodefailed: "Cannot send verification code to this number!",
    retrysendcode: "Retry with a different phone number",
  },
};

export default languageConfigs;
